import React, { useContext, useEffect } from "react";
import { ProjectContext } from "../../context/Hours";
import { getProjectHours, getSubProjectHours } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import ProjectHoursList from "./ProjectHoursList";

interface Props {
  selector: HoursSelector;
}

export enum HoursSelector {
  projects = "Hankkeet",
  subprojects = "Pienprojektit",
  maintenance = "Ylläpito",
}

const ProjectsList = ({ selector }: Props) => {
  const hoursData = useContext(ProjectContext);
  const {
    projectFetchStatus,
    subProjectFetchStatus,
    projectData,
    subProjectData,
    selectedScope,
    setProjectStatus,
    setSubProjectStatus,
    setSubProjects,
    setProjects,
  } = hoursData;

  const handleFetchProjects = () => {
    setProjectStatus(HttpRequestStatus.loading);

    getProjectHours(selectedScope)
      .then((hours) => {
        setProjects(projectData.concat(hours));
      })
      .catch((err) => {
        console.log(err);
        setProjectStatus(HttpRequestStatus.error);
      });
  };

  const handleFetchSubProjects = () => {
    setSubProjectStatus(HttpRequestStatus.loading);

    getSubProjectHours()
      .then((subHours) => {
        setSubProjects(subHours);
      })
      .catch((err) => {
        console.log(err);
        setSubProjectStatus(HttpRequestStatus.error);
      });
  };

  useEffect(() => {
    switch (selector) {
      case HoursSelector.projects:
        statusNull(projectFetchStatus) && handleFetchProjects();
        break;
      case HoursSelector.subprojects || HoursSelector.maintenance:
        statusNull(subProjectFetchStatus) && handleFetchSubProjects();
        break;
      default:
        break;
    }
  });

  return (
    <div className="NSF-HoursList">
      <div className="NSF-Row">
        <h2>{selector}</h2>
      </div>
      <div className="NSF-Row">
        {selector === HoursSelector.projects && (
          <ProjectHoursList projectHours={projectData} />
        )}
        {selector === HoursSelector.subprojects && (
          <ProjectHoursList
            subProjectHours={subProjectData.filter(
              (dataItem) => !dataItem.SubProjectName.includes("1. Ylläpito")
            )}
          />
        )}
        {selector === HoursSelector.maintenance && (
          <ProjectHoursList
            subProjectHours={subProjectData.filter(
              (dataItem) => dataItem.SubProjectName.includes("1. Ylläpito")
            )}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectsList;
