import React, { ChangeEvent } from "react";
import Input from "../Input/Input";


interface props {
  handleFreeSearch: (T: any) => any;
}

export const EmployeesFilter = ({handleFreeSearch} : props) => {

  const freeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleFreeSearch(value.toLowerCase());
  }

  return (
    <div className="NSF-Employees-Filter">
      <Input className="NSF-Employees-Filter--SearchInput" id="freeSearch" placeholder="HAE TYÖNTEKIJÄÄ" onChange={freeSearch} />
    </div>
  )
}