import React, { FunctionComponent } from "react";
import { HttpRequestStatus } from "../utils/http";

export enum Scope {
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

interface Context {
  projectFetchStatus: HttpRequestStatus;
  subProjectFetchStatus: HttpRequestStatus;
  timeSheetFetchStatus: HttpRequestStatus
  projectData: ProjectHoursData[];
  subProjectData: SubProjectHoursData[];
  timeSheets: TimeSheetEntry[];
  selectedSubProject?: SubProjectHoursData;
  selectedScope: Scope;

  // Fucntions for mutating Context
  setProjectStatus: (status: HttpRequestStatus) => void;
  setSubProjectStatus: (status: HttpRequestStatus) => void;
  setTimeSheetStatus: (status: HttpRequestStatus) => void;
  setProjects: (projectHours: ProjectHoursData[]) => void;
  setSubProjects: (Project: SubProjectHoursData[]) => void;
  setTimeSheets: (timeSheets: TimeSheetEntry[]) => void;
  setSelectedSubProject: (subProject: SubProjectHoursData) => void;
  setSelectedScope: (scope: Scope) => void;
  clearState: () => void;
}

const defaultFn = () => {
  throw Error("ContextFn has not been initialized correctly");
};

// Default context is used to initialize the context-slice.
const defaultState: Context = {
  projectFetchStatus: HttpRequestStatus.null,
  subProjectFetchStatus: HttpRequestStatus.null,
  timeSheetFetchStatus: HttpRequestStatus.null,
  projectData: [],
  subProjectData: [],
  timeSheets: [],
  selectedScope: Scope.MONTH,
  setSubProjectStatus: defaultFn,
  setProjectStatus: defaultFn,
  setTimeSheetStatus: defaultFn,
  setProjects: defaultFn,
  setSubProjects: defaultFn,
  setTimeSheets: defaultFn,
  setSelectedSubProject: defaultFn,
  clearState: defaultFn,
  setSelectedScope: defaultFn,
};

export const ProjectContext = React.createContext<Context>(defaultState);
export const ProjectConsumer = ProjectContext.Consumer;

const ProjectProvider: FunctionComponent = (props) => {
  const [state, setState] = React.useState<Context>(defaultState);

  const setProjects = (projectData: ProjectHoursData[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        projectData: projectData,
        projectFetchStatus: HttpRequestStatus.ready,
      };
    });
  };

  const setSubProjects = (projectHours: SubProjectHoursData[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        subProjectData: projectHours,
        subProjectFetchStatus: HttpRequestStatus.ready,
      };
    });
  };

  const setTimeSheets = (timeSheets: TimeSheetEntry[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        timeSheets,
        timeSheetFetchStatus: HttpRequestStatus.ready
      }
    })
  }

  const setProjectStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, projectFetchStatus: status };
    });
  };

  const setSubProjectStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, subProjectFetchStatus: status };
    });
  };

  const setTimeSheetStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, timeSheetFetchStatus: status};
    })
  }

  const setSelectedScope = (scope: Scope) => {
    setState((prev: Context) => {
      return { ...prev, selectedScope: scope };
    });
  };

  const clearState = () => {
    setState(() => {
      return initState;
    });
  };

  const initState: Context = {
    ...state,
    setProjects,
    setSubProjects,
    setTimeSheets,
    setProjectStatus,
    setSubProjectStatus,
    setSelectedScope,
    setTimeSheetStatus,
    clearState,
  };

  return (
    <ProjectContext.Provider value={initState}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
