import cuid from "cuid";
import React from "react";
import { Link } from "react-router-dom";
import DataCard from "../Card/DataCard";

interface Props {
  projectHours?: ProjectHoursData[];
  subProjectHours?: SubProjectHoursData[];
}

const ProjectHoursList = ({ projectHours, subProjectHours }: Props) => {
  return (
    <React.Fragment>
      {projectHours?.map((hoursData) => (
        <div className="NSF-Card" key={cuid()}>
          <DataCard
            label={hoursData.ProjectName}
            maxValue={hoursData.BookedHours}
            value={hoursData.ResourcedHours}
          />
        </div>
      ))}
      {subProjectHours?.map((hoursData) => (
        <Link to={`/portal/subproject/${hoursData.SubProjectId}`} className="NSF-Card" key={cuid()}>
          <DataCard
            label={hoursData.SubProjectName}
            maxValue={hoursData.BookedHours}
            value={hoursData.ResourcedHours}
          />
        </Link>
      ))}
    </React.Fragment>
  );
};

export default ProjectHoursList;
