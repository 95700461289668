import React from "react";
import { useHistory } from "react-router";

interface Props {
  text: string;
  color?: string;
  submit?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  handleClick?: (T: any) => any;
}

const ActionButton = ({ text, color, type, disabled, handleClick }: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={handleClick}
      className={`NSF-ActionButton NSF-ActionButton--${color}`}
    >
      <p>{text}</p>
    </button>
  );
};

interface BackButtonProps {
  text: string;
  color?: string;
  className?: string;
}

export const BackButton = ({ text, color, className }: BackButtonProps) => {
  const history = useHistory();  
  
  return (
    <div className={className}>
      <ActionButton text={text} color={color} handleClick={history.goBack}/>
    </div>
  )
}

export default ActionButton;
