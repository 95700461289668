import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import { AssetsContext } from "../../context/Assets";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import { getAssets } from "../../core/api";
import AssetForm from "../../components/Assets/AssetForm";
import { updateAsset } from "../../core/api";

interface AssetParams {
  assetId: string;
}

let defaultAsset: Asset = {
  id: "",
  displayName: "",
  companyId: "",
  employeeId: "",
  type: "PHONE",
  purchaseDate: new Date(),
  assetMetadata: [],
};

const UpdateAsset = () => {
  const assetsContext = useContext(AssetsContext);

  const { assetId } = useParams<AssetParams>();

  const {
    assetsFetchStatus,
    assets,
    setAssets,
    setAssetsFetchStatus,
  } = assetsContext;

  useEffect(() => {
    if (statusNull(assetsFetchStatus)) {
      (async () => {
        try {
          setAssetsFetchStatus(HttpRequestStatus.loading);
          const assets = await getAssets();
          setAssetsFetchStatus(HttpRequestStatus.ready);
          setAssets(assets);
        } catch (err) {
          console.log(err);
          setAssetsFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
  }, [assetsFetchStatus, setAssets, setAssetsFetchStatus]);

  const findAsset = () => {
    const asset = assets.find((asset) => asset.id === assetId);
    if (asset) return asset;
    return defaultAsset;
  };

  const handleSubmit = async (form: Asset) => {
    console.log('Update:', form);
    
    return updateAsset(form);
  }

  return (
    <div className="NSF-AssetForm">
      <div className="NSF-Row">
        <div className="NSF-PageTitle">Muokkaa laitetietoja</div>
      </div>
      <AssetForm defaultAsset={findAsset()} submitButtonText="Päivitä" submitForm={handleSubmit} />
    </div>
  );
};

export default UpdateAsset;
