import React, { useContext } from "react";
import cuid from "cuid";

import { IconContext } from "react-icons";
import { ImCross } from "react-icons/im";

import { OrderContext } from "../../context/Order";

interface RowProps {
  visible: boolean;
  assets?: OrderableAsset[];
  employee: Employee;
}

const OrderDeviceList = ({ visible, assets, employee }: RowProps) => {
  const orderContext = useContext(OrderContext);
  const { dispatch } = orderContext;
  if (!visible) {
    return null;
  }

  if (!assets || assets.length === 0) {
    return <div className="NSF-Row NSF-OrderDeviceList__row--noDevices"><p>Ei valittuja tuotteita</p></div>
  }

  const handleOnDelete = (asset: OrderableAsset) => {
    if(employee.id !== undefined) {
      dispatch({type: "DELETE", payload: {employeeId: employee.id.toString(), assetId: asset.id.toString()}})
    } 
  }

  return (
    <div className="NSF-Row NSF-OrderDeviceList__row">
      {assets.map((asset) => {
        return (
          <IconContext.Provider value={{size: "0.8em"}} key={cuid()}>
          <div className="NSF-Row NSF-OrderDeviceList__row--asset">
            <p className="NSF-OrderDeviceList__row--asset--name">{asset.displayName}</p>
            <p className="NSF-OrderDeviceList__row--asset--price">{asset.price.toFixed(2)} &euro;</p>
            <ImCross className="NSF-OrderDeviceList__row--asset--icon" onClick={() => handleOnDelete(asset)}/>
          </div>
          </IconContext.Provider>
        );
      })}
    </div>
  );
};
export default OrderDeviceList;
