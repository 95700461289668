import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  FormEvent,
} from "react";
import { useHistory } from "react-router";
import { AiFillPlusCircle } from "react-icons/ai";
import Dropdown from "react-dropdown";

import { AssetsContext } from "../../context/Assets";
import { HttpRequestStatus } from "../../utils/http";
import ActionButton from "../Button/Button";

interface metadata {
  objectKey: string;
  objectValue: string;
}

interface FormProps {
  defaultAsset: Asset;
  submitButtonText: string;
  submitForm: (T: any) => any;
}

const AssetForm = ({
  defaultAsset,
  submitButtonText,
  submitForm,
}: FormProps) => {
  const [asset, setAsset] = useState<Asset>(defaultAsset);
  const [newAssetMetadata, setNewAssetMetadata] = useState<metadata[]>([]);
  const [ formError, setFormError ] = useState<string>("");
  const context = useContext(AssetsContext);
  const history = useHistory();
  const { setAssetsFetchStatus, setAssetsSubmitStatus } = context;

  useEffect(() => {
    setAsset((prev) => ({
      ...prev,
      employeeId: defaultAsset.employeeId
        ? defaultAsset.employeeId
        : prev.employeeId,
      companyId: defaultAsset.companyId
        ? defaultAsset.companyId
        : prev.companyId,
    }));
  }, [defaultAsset]);

  const formatDisplayDate = (date: Date | undefined) => {
    if (date) {
      return new Intl.DateTimeFormat("fr-CA").format(new Date(date)).toString();
    } else return "";
  };

  const addNewRow = () => {
    setNewAssetMetadata([
      ...newAssetMetadata,
      { objectKey: "", objectValue: "" },
    ]);
  };

  const getOrderStatus = () => {
    return asset.order?.status;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (asset.employeeId === "") {
      setFormError("Valitse työntekijä")
      return;
    }
    setFormError("");
    event.preventDefault();

    const assetData = { ...asset };
    assetData.assetMetadata = [...assetData.assetMetadata, ...newAssetMetadata];
    setAsset(assetData);
    setNewAssetMetadata([]);

    try {
      setAssetsSubmitStatus(HttpRequestStatus.loading);
      await submitForm(assetData);
      setAssetsSubmitStatus(HttpRequestStatus.ready);
      setAssetsFetchStatus(HttpRequestStatus.null);
    } catch (err) {
      console.log(err);
    }
    history.goBack();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAsset((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "purchaseDate") {
      const order: Order | any = { ...asset.order };
      order.orderPlacedDate = value;

      setAsset((prev) => ({
        ...prev,
        order: { ...order },
      }));
    }
  };

  const handleMetadataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const assetData = { ...asset };
    const metadata = assetData.assetMetadata.find(
      (asset) => asset.objectKey === name
    );
    if (metadata) {
      metadata.objectValue = value;
      setAsset(assetData);
    }
  };

  const handleNewMetadataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.includes("newObject")) {
      const [type, idx] = name.split("-");
      let meta = [...newAssetMetadata];
      const newMeta = meta.splice(parseInt(idx), 1)[0];
      if (type === "newObjectKey") {
        newMeta.objectKey = value;
      } else if (type === "newObjectValue") {
        newMeta.objectValue = value;
      }
      meta.splice(parseInt(idx), 1, newMeta);
      setNewAssetMetadata(meta);
    }
  };

  const handleTypeChange = (option: any) => {
    setAsset((prev) => ({
      ...prev,
      type: option.value ? option.value : prev.type,
    }));
  };

  const handleOrderStatusChange = (option: any) => {
    const order: Order | any = { ...asset.order };
    order.status = option.value;
    if (option.value !== "DELIVERED") {
      order.estimatedDeliveryDate = defaultAsset.order?.estimatedDeliveryDate
        ? defaultAsset.order?.estimatedDeliveryDate
        : order.estimatedDeliveryDate;
    }
    setAsset((prev) => ({
      ...prev,
      order: { ...order },
    }));
  };

  const handleDeliveryDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const order: Order | any = { ...asset.order };
    order.estimatedDeliveryDate = value;
    setAsset((prev) => ({
      ...prev,
      order: { ...order },
    }));
  };

  const assetTypeOptions = [
    // | "SERVER" | "FIREWALL" | "SWITCH" | "WLAN" | "PHONE" | "COMPUTER" | "SOFTWARE";

    { label: "Puhelin", value: "PHONE" },
    { label: "Tietokone", value: "COMPUTER" },
    { label: "Ohjelmisto", value: "SOFTWARE" },
    { label: "Reititin", value: "SWITCH" },
    { label: "Palomuuri", value: "FIREWALL" },
  ];

  const orderstatusOptions = [
    // "ORDERED" | "SHIPMENT" | "DELIVERED";

    { label: "Tilattu", value: "ORDERED" },
    { label: "Toimituksessa", value: "SHIPMENT" },
    { label: "Toimitettu", value: "DELIVERED" },
  ];

  return (
    <React.Fragment>
      <div className="NSF-Row">
        <div className="NSF-AssetForm__form">
          <div className="NSF-AssetForm__form--input">
            <div className="NSF-Input__label">Nimi</div>
            <div className="NSF-Input">
              <input
                type="text"
                value={asset.displayName as string}
                name="displayName"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="NSF-AssetForm__form--input">
            <div className="NSF-Input__label">Tyyppi</div>
            <div className="NSF-Input">
              <Dropdown
                options={assetTypeOptions}
                value={asset.type}
                onChange={handleTypeChange}
              />
            </div>
          </div>
          <div className="NSF-AssetForm__form--input">
            <div className="NSF-Input__label">Ostospäivämäärä</div>
            <div className="NSF-Input">
              <input
                type="date"
                value={formatDisplayDate(asset.purchaseDate)}
                name="purchaseDate"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="NSF-AssetForm__form--input">
            <div className="NSF-Input__label">Status</div>
            <div className="NSF-Input">
              <Dropdown
                options={orderstatusOptions}
                value={getOrderStatus()}
                onChange={handleOrderStatusChange}
                placeholder="Valitse..."
              />
            </div>
          </div>
          {getOrderStatus() === "DELIVERED" && (
            <div className="NSF-AssetForm__form--input">
              <div className="NSF-Input__label">Toimituspäivämäärä</div>
              <div className="NSF-Input">
                <input
                  type="date"
                  value={formatDisplayDate(asset.order?.estimatedDeliveryDate)}
                  name="deliveryDate"
                  onChange={handleDeliveryDateChange}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
          )}
          {/* AssetMetadata */}
          {asset.assetMetadata.map(({ objectKey, objectValue }) => {
            return (
              <div
                className="NSF-AssetForm__form--input"
                key={objectKey as string}
              >
                <div className="NSF-Input__label">{objectKey}</div>
                <div className="NSF-Input">
                  <input
                    type="text"
                    value={objectValue as string}
                    onChange={handleMetadataChange}
                    name={objectKey as string}
                  />
                </div>
              </div>
            );
          })}
          {newAssetMetadata.map((key, value) => {
            const assetLabelId = `newObjectKey-${value}`;
            const assetTextId = `newObjectValue-${value}`;
            return (
              <div className="NSF-AssetForm__form--input" key={value}>
                <div className="NSF-Input">
                  <input
                    type="text"
                    placeholder="Otsikko"
                    value={newAssetMetadata[value].objectKey as string}
                    onChange={handleNewMetadataChange}
                    name={assetLabelId}
                  />
                </div>
                <div className="NSF-Input">
                  <input
                    type="text"
                    placeholder="Teksti"
                    value={newAssetMetadata[value].objectValue as string}
                    onChange={handleNewMetadataChange}
                    name={assetTextId}
                  />
                </div>
              </div>
            );
          })}
          <div className="NSF-AssetForm__form--new">
            <AiFillPlusCircle color="red" onClick={addNewRow} /> Lisää kenttä
          </div>
        </div>
      </div>
      {formError !== "" && (
      <div className="NSF-Row">
        <div className="NSF-AssetForm__error">
          <p>{formError}</p>
        </div>
      </div>
      )}
      <div className="NSF-Row">
        <div className="NSF-AssetForm__submit--button">
          <ActionButton
            text={submitButtonText}
            color="red"
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssetForm;
