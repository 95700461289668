import React, { FunctionComponent } from "react";
import AssetsProvider from "./Assets";
import EmployeesProvider from "./Employees";
import ProjectProvider from "./Hours";
import UserProvider from "./User";
import OrderProvider from "./Order";

const RootProvider: FunctionComponent = (props) => {
  return (
    <UserProvider>
      <ProjectProvider>
        <AssetsProvider>
          <OrderProvider>
            <EmployeesProvider>{props.children}</EmployeesProvider>
          </OrderProvider>
        </AssetsProvider>
      </ProjectProvider>
    </UserProvider>
  );
};

export default RootProvider;
