import React, { useState } from "react";
import { Link } from "react-router-dom";
import cuid from "cuid";
import { IoIosArrowForward } from "react-icons/io";
import EmployeeDevices from "./EmployeeDevices";
import DeviceList from "../Assets/DeviceList";
import { FaCartPlus, FaUserEdit } from "react-icons/fa";

interface RowProps {
  employee: Employee;
  assets: Asset[];
}

const EmployeeRow = ({ employee, assets }: RowProps) => {
  const [showDevices, setShowDevices] = useState(false);
  const { firstName, lastName, id } = employee;

  const toggleShowDevices = () => {
    setShowDevices(!showDevices);
  };

  return (
    <React.Fragment>
      <div className="NSF-EmployeesList__row">
        <div className="NSF-EmployeesList__row--arrow">
          <button
            onClick={toggleShowDevices}
            className={`NSF-EmployeesList__row--arrow-animation ${
              showDevices && "NSF-EmployeesList__row--arrow-active"
            }`}
          >
            <IoIosArrowForward />
          </button>
        </div>
        <div className="NSF-EmployeesList__row--name">
          <p>{firstName} {lastName}</p>
        </div>
        <div className="NSF-EmployeesList__row--devices NSF-EmployeesList__row--devices--wide">
          <EmployeeDevices assets={assets} />
        </div>
        <div className="NSF-EmployeesList__row--order">
          <Link to={`/portal/assets/order/${id}`}>
            <FaCartPlus /> Tilaa
          </Link>
        </div>
        <div className="NSF-EmployeesList__row--modify">
          <Link to={`/portal/employees/edit/${id}`}>
            <FaUserEdit /> Muokkaa
          </Link>
        </div>
      </div>
      <DeviceList assets={assets} visible={showDevices} />
    </React.Fragment>
  );
};

interface Props {
  employees: Employee[];
  assets: Asset[];
}

const EmployeesList = ({ employees, assets }: Props) => {
  return (
    <div className="NSF-EmployeesList">
      {employees.map((employee) => (
        <EmployeeRow
          key={cuid()}
          employee={employee}
          assets={assets.filter((asset) => asset.employeeId === employee.id)}
        />
      ))}
    </div>
  );
};

export default EmployeesList;
