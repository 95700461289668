import React from "react";
import PercentageChart from "../Chart/Chart";

interface Props {
  value: number;
  maxValue: number;
  label: string;
  sublabel?: string;
}

const DataCard = (props: Props) => {
  return (
    <div className="NSF-DataCard">
      <div className="NSF-DataCard__info">
        <h2 className="NSF-DataCard__info--label">{props.label}</h2>
        <h3 className="NSF-DataCard__info--sublabel">{props.sublabel}</h3>
      </div>
      <div className="NSF-DataCard__visualization">
        <PercentageChart amount={props.value} maximum={props.maxValue} />
        <h3>Laskutetut tunnit</h3>
        <h2>
          {props.value}t / {props.maxValue}t
        </h2>
      </div>
    </div>
  );
};

export default DataCard;
