import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { EmployeeForm, FormState } from "../../components/Employees/EmployeeForm";
import { EmployeesContext } from "../../context/Employees";
import { updateEmployee, getEmployees, removeEmployee } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import { notEmpty, validEmail } from "../../utils/validate";
import ActionButton, { BackButton } from "../../components/Button/Button";

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  valid: false,
  submitted: false,
};

interface updateParams {
  userId: string;
}

const UpdateEmployee = () => {
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const employeesContext = useContext(EmployeesContext);
  const {
    employeesFetchStatus,
    employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = employeesContext;

  const history = useHistory();
  const { userId } = useParams<updateParams>();
  
  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployeesFetchStatus(HttpRequestStatus.ready);
          setEmployees(employees);
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
    (async () => {
      const employee = employees.find((employee) => employee.id === userId) as FormState;
      
      if (employee) {
        setFormState({
          ...employee,
          valid: validate(employee),
        });
      }
    })();

  }, [
    employeesFetchStatus,
    setEmployeesFetchStatus,
    setEmployees,
    employees,
    userId,
  ]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validate = (formState: FormState) => {
    return (
      notEmpty(formState.firstName) &&
      notEmpty(formState.lastName) &&
      validEmail(formState.email)
    );
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState((prev) => ({
      ...prev,
      submitted: true,
    }));
    try {
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.loading);
      await updateEmployee(userId, formState);
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.ready);
      employeesContext.setEmployeesFetchStatus(HttpRequestStatus.null);
      history.push("/portal/employees");
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.loading);
      await removeEmployee(userId);
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.ready);
      employeesContext.setEmployeesFetchStatus(HttpRequestStatus.null);
      history.push("/portal/employees");
    } catch (err) {
      console.log(err);
    }
  }


  const { valid, submitted } = formState;

  return (
    <div className="NSF-EmployeeForm">
      <div className="NSF-Row Remove-Employee">
        <ActionButton
          type="button"
          text="Poista"
          handleClick={handleDelete}
          color="black"
        />
      </div>
      <div className="NSF-Row">
        <h1 className="NSF-PageTitle">Muokkaa työntekijän tietoja</h1>
      </div>
      <div className="NSF-Row">
        <EmployeeForm
          formState={formState}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          valid={valid && !submitted}
        />
      </div>
      <div className="NSF-Row">
        <BackButton text="Takaisin" className="NSF-EmployeeForm--back" />
      </div>
    </div>
  );
};

export default UpdateEmployee;
