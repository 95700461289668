import React, { ChangeEvent } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Input from "../Input/Input";

const defaultOptions = {
  type: {
    options: [{value: "TYPE_ALL", label: "Kaikki"}, {value: "PHONE", label: "Puhelimet"}, {value: "COMPUTER", label: "Tietokoneet"}, {value: "FIREWALL", label: "Palomuurit"}, {value: "SWITCH", label: "Kytkimet"}, {value: "WLAN", label: "Langattomat Tukiasemat"}, {value: "SOFTWARE", label: "Palvelimet"}],
    className: 'myOptionClassName'
  },
  status: {
    options: [{value: "TYPE_ALL", label: "Kaikki"}, {value: "ORDERED", label: "Tilattu"}, {value: "SHIPMENT", label: "Toimituksessa"}, {value: "DELIVERED", label: "Toimitettu"}]
  }
}

interface props {
  handleSelectType: (T: any) => any;
  handleSelectStatus: (T: any) => any;
  handleFreeSearch: (T: any) => any;
}


const AssetFilter = ({handleSelectType, handleSelectStatus, handleFreeSearch}: props) => {

  const _onSelectType = (option: any) => {
    handleSelectType(option);
  }  
  const _onSelectStatus = (option: any) => {
    handleSelectStatus(option);
  }

  const freeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleFreeSearch(value.toLowerCase());
  }

  return (
    <div className="NSF-AssetsFilter">
      <div className="NSF-AssetsFilter--item">
        <Input className="NSF-AssetsFilter--SearchInput"  id="freeSearch" placeholder="VAPAA HAKU"  onChange={freeSearch} />
      </div>
      <div className="NSF-AssetsFilter--item">
      {/* <h2 className="NSF-AssetsFilter--label">Tyyppi</h2> */}
      <Dropdown options={defaultOptions.type.options} onChange={_onSelectType} placeholder="TYYPPI"/>
      </div>
      <div className="NSF-AssetsFilter--item">
      {/* <h2 className="NSF-AssetsFilter--label">Status</h2> */}
      <Dropdown options={defaultOptions.status.options} onChange={_onSelectStatus} placeholder="STATUS"/>
      </div>
    </div>
  )
}

export default AssetFilter;
