import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataCard from "./DataCard";

interface Props {
  projectData: HoursData[];
  label: string;
  sublabel?: string;
  path: string;
}

interface State {
  totalBookedHours: number;
  totalResourcedHours: number;
}

const defaultState: State = {
  totalBookedHours: 0,
  totalResourcedHours: 0,
};

const OverviewCard = ({ projectData, path, label, sublabel }: Props) => {
  const [state, setState] = useState<State>(defaultState);

  useEffect(() => {
    const totalData = projectData.reduce(
      (acc, data) => {
        acc.ResourcedHours += data.ResourcedHours;
        acc.BookedHours += data.BookedHours;
        return acc;
      },
      { ResourcedHours: 0, BookedHours: 0, AccountId: 0 }
    );
    setState({
      totalResourcedHours: totalData.ResourcedHours,
      totalBookedHours: totalData.BookedHours,
    });
  }, [projectData]);

  return (
    <Link to={path} className="NSF-Card">
      <DataCard
        label={label}
        sublabel={sublabel}
        maxValue={state.totalBookedHours}
        value={state.totalResourcedHours}
      />
    </Link>
  );
};

export default OverviewCard;
