import React, { useContext, useEffect } from "react";
import Dropdown from "react-dropdown";

import { EmployeesContext } from "../../context/Employees";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import { getEmployees } from "../../core/api";

interface DropdownProps {
  onSelect: (T: any) => any;
}

export const EmployeesDropdown = ({onSelect}: DropdownProps) => {
  const context = useContext(EmployeesContext);

  const {
    employeesFetchStatus,
    employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = context;

  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployees(employees);
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
  }, [employees, employeesFetchStatus, setEmployees, setEmployeesFetchStatus]);

  const handleSelect = (option: any) => {
    onSelect(employees.find(employee => `${employee.firstName} ${employee.lastName}` === option.value))
  };
  return (
    <div>
      <Dropdown
        options={employees.map(employee => `${employee.firstName} ${employee.lastName}`)}
        onChange={handleSelect}
        placeholder="Valitse työntekijä"
      />
    </div>
  );
};
