import React, { createContext, FunctionComponent, useState } from "react";
import { HttpRequestStatus } from "../utils/http";

interface Context {
  employeesFetchStatus: HttpRequestStatus;
  employeeSubmitStatus: HttpRequestStatus;
  employees: Employee[];

  setEmployeesFetchStatus: (status: HttpRequestStatus) => void;
  setEmployeeSubmitStatus: (status: HttpRequestStatus) => void;
  setEmployees: (employees: Employee[]) => void;
}

const defaultFn = () => {
  throw Error("ContextFn has not been initialized correctly");
};

const defaultState = {
  employeesFetchStatus: HttpRequestStatus.null,
  employeeSubmitStatus: HttpRequestStatus.null,
  employees: [],

  setEmployeesFetchStatus: defaultFn,
  setEmployeeSubmitStatus: defaultFn,
  setEmployees: defaultFn,
};

export const EmployeesContext = createContext<Context>(defaultState);

const EmployeesProvider: FunctionComponent = (props) => {
  const [state, setState] = useState<Context>(defaultState);

  const setEmployees = (employees: Employee[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        employees,
      };
    });
  };

  const setEmployeesFetchStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, employeesFetchStatus: status };
    });
  };

  const setEmployeeSubmitStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, employeeSubmitStatus: status };
    });
  };

  const initState: Context = {
    ...state,
    setEmployees,
    setEmployeesFetchStatus,
    setEmployeeSubmitStatus,
  };

  return (
    <EmployeesContext.Provider value={initState}>
      {props.children}
    </EmployeesContext.Provider>
  );
};

export default EmployeesProvider;
