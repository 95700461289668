import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import cuid from "cuid";

import { OrderContext } from "../../context/Order";
import { EmployeesContext } from "../../context/Employees";
import ActionButton from "../../components/Button/Button";
import { submitOrder, getEmployees } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";

const OrderConfirm = () => {
  const history = useHistory();
  const orderContext = useContext(OrderContext);
  const employeesContext = useContext(EmployeesContext);

  const { state, dispatch } = orderContext;
  const {
    employeesFetchStatus,
    employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = employeesContext;

  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployeesFetchStatus(HttpRequestStatus.ready);
          setEmployees(employees);
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
  }, [employeesFetchStatus, setEmployees, setEmployeesFetchStatus]);

  const convertOrderToAssets = (order: ShoppingCartItem[]): Asset[] => {
    const assets = order
      .map((item) => {
        return item.assets
          ? item.assets.map((asset) => {
              return {
                id: asset.id,
                companyId: employees[0].companyId,
                employeeId: item.employee.id,
                type: asset.type,
                displayName: asset.displayName,
                purchaseDate: new Date(),
                assetMetadata: [],
              } as Asset;
            })
          : [];
      })
      .flat();

    return assets as Asset[];
  };

  const totalPrice = () => {
    return state.order.reduce(
      (acc, item) =>
        item.assets
          ? item.assets!.reduce((acc2, asset) => asset.price + acc2, 0) + acc
          : acc,
      0
    );
  };

  const sendOrder = () => {
    const assets = convertOrderToAssets(state.order);
    submitOrder(assets)
      .then((data) => {
        dispatch({ type: "CLEAR" });
        history.push("/portal");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="NSF-Row NSF-OrderValidate">
      <h2 className="NSF-PageTitle">Tilausvahvistus</h2>
      <div className="NSF-OrderValidate__item NSF-OrderValidate__filter">
        <p>Tuotetyyppi</p>
        <p>Hinta</p>
        <p>Työntekijä</p>
      </div>
      {state.order.map((item) => {
        return (
          <React.Fragment key={cuid()}>
            {item.assets?.map((asset) => {
              return (
                <div className="NSF-OrderValidate__item" key={cuid()}>
                  <p>{asset.displayName}</p>
                  <p>{asset.price.toFixed(2)} &euro;</p>
                  <p>
                    {" "}
                    {item.employee?.firstName} {item.employee?.lastName}{" "}
                  </p>
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
      <div className="NSF-OrderValidate NSF-OrderValidate__confirm">
        <div className="NSF-OrderValidate__confirm--back">
          <ActionButton
            text="&larr; Muokaa valintoja"
            color="white"
            handleClick={sendOrder}
          />
        </div>
        <p>
          Yhteensä: <span>{totalPrice()} €</span>
        </p>
        <div className="NSF-OrderValidate__confirm--back">
          <ActionButton text="Vahvista" handleClick={sendOrder} />
        </div>
      </div>
    </div>
  );
};

export default OrderConfirm;
