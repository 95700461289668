import React from "react";
import { useLocation } from "react-router";
import ProjectsList, { HoursSelector } from "../../components/ProjectsList/ProjectsList";

const ProjectHours = () => {
  let selector = HoursSelector.projects;
  const location = useLocation();
  switch (location.pathname) {
    case "/portal/projecthours":
      selector = HoursSelector.projects;
      break;
    case "/portal/subprojecthours":
      selector = HoursSelector.subprojects;
      break;
    case "/portal/maintenancehours":
      selector = HoursSelector.maintenance;
      break;
    default:
      break;
  }

  return (
    <section className="NSF-PortalContent">
      <div className="NSF-Column">
        <ProjectsList selector={selector} />
      </div>
    </section>
  );
};

export default ProjectHours;
