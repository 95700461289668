import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { FaCartPlus } from "react-icons/fa";
import cuid from "cuid";

import OrderDeviceList from "./OrderDeviceList";

interface RowProps {
  item: ShoppingCartItem;
}

const EmployeeRow = ({ item }: RowProps) => {
  const [showDevices, setShowDevices] = useState(false);

  const { employee, assets } = item;
  const { id, firstName, lastName } = employee;

  useEffect(() => {
    if(assets) {
      setShowDevices(true);
    }
  }, [assets]);

  const toggleShowDevices = () => {
    setShowDevices(!showDevices);
  };

  const getEmployeeOrder = () => {
    if (assets) {
      return "";
    }
    return "";
  };

  const hasOrder = () => {
    if(assets) {
      return assets.length > 0
    }
    return false;
  }

  return (
    <React.Fragment>
    <div className="NSF-EmployeesList__row">
      <div className="NSF-EmployeesList__row--arrow">
        <button
          onClick={toggleShowDevices}
          className={`NSF-EmployeesList__row--arrow-animation ${
            showDevices && "NSF-EmployeesList__row--arrow-active"
          }`}
        >
          <IoIosArrowForward />
        </button>
      </div>
      <div className="NSF-OrderEmployeesList__row--name">
        <p>
          {firstName} {lastName}
        </p>
      </div>
      <div className="NSF-OrderEmployeesList__row--assets">
        <p>{getEmployeeOrder()}</p>
      </div>
      <div className="NSF-OrderEmployeesList__row--order">
        <Link to={`/portal/assets/order/${id}`}>
          <FaCartPlus /> {hasOrder() ? "MUOKAA VALINTOJA" : "VALITSE TUOTTEET"}
        </Link>
      </div>
    </div>
    <OrderDeviceList visible={showDevices} assets={assets} employee={employee}/>
    </React.Fragment>
  );
};

interface OrderProps {
  cart: ShoppingCart;
}

const OrderEmployeesList = ({ cart }: OrderProps) => {
  const { order } = cart;

  return (
    <div className="NSF-EmployeesList">
      {order.map((item) => (
        <EmployeeRow key={cuid()} item={item} />
      ))}
    </div>
  );
};

export default OrderEmployeesList;
