import cuid from "cuid";
import React from "react";
import {
  FaLaptop,
  FaMobileAlt
} from "react-icons/fa";

import { MdSecurity } from "react-icons/md";
import { TiFlowSwitch } from "react-icons/ti";
import { RiRouterLine, RiBookLine } from "react-icons/ri";

interface Props {
  assets: Asset[];
}

const EmployeeDevices = ({ assets }: Props) => {
  const getOrderStatus = (asset: Asset) => {
    return asset.order?.status;
  };

  const getOrderStatusTooltip = (asset: Asset) => {
    const status = asset.order?.status;
    switch(status) {
      case "ORDERED":
        return <><span className="NSF-DeviceStatus--tooltip-displayName">{asset.displayName}</span><span>Tilattu: {formatDate(asset.order?.orderPlacedDate)}</span></>;
      case "SHIPMENT":
        return <><span className="NSF-DeviceStatus--tooltip-displayName">{asset.displayName}</span><span>Tilattu: {formatDate(asset.order?.orderPlacedDate)}<br/>Arvioitu saapumispäivä: {formatDate(asset.order?.estimatedDeliveryDate)}</span></>;
      case "DELIVERED":
        return <><span className="NSF-DeviceStatus--tooltip-displayName">{asset.displayName}</span><span>Tilattu: {formatDate(asset.order?.orderPlacedDate)}<br/>Toimitettu: {formatDate(asset.order?.estimatedDeliveryDate)}</span></>;
      default:
        return <span>Laitetta ei ole tilattu</span>;
    }
  }

  const formatDate = (date: Date | undefined) => {
    if(date === undefined) return "";
    return new Intl.DateTimeFormat("fi-FI").format(new Date(date));
  };

  const assetIcon = (type: AssetType) => {
    switch (type) {
      case "PHONE":
        return <FaMobileAlt />;
      case "COMPUTER":
        return <FaLaptop />;
      case "FIREWALL":
        return <MdSecurity />;
      case "SWITCH":
        return <TiFlowSwitch />;
      case "WLAN":
          return <RiRouterLine />;
      case "SOFTWARE":
        return <RiBookLine />;
      default:
        break;
    }
  };

  // TODO: css rules for different order statuses, what are last two asset types?

  return (
    <div className="NSF-EmployeeDevices">
      {assets.map((asset) => <div
        className={`NSF-EmployeeDevices__deviceIcon NSF-EmployeeDevices__deviceIcon--${getOrderStatus(asset)}`}
        key={cuid()}
      >
        {assetIcon(asset.type)}
        <p className="NSF-DeviceStatus--tooltip">{getOrderStatusTooltip(asset)}</p>
      </div>
      )}
    </div>
  );
};

export default EmployeeDevices;
