import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import DataCard from "../../components/Card/DataCard";
import ScopeSelector from "../../components/ScopeSelector/ScopeSelector";
import TimeSheets from "../../components/TimeSheets/TimeSheets";
import { ProjectContext } from "../../context/Hours";
import { getSubProjectHours, getTimeSheetEntries } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";

interface SiteParams {
  subProjectId: string;
}

const SubProject = () => {
  let { subProjectId } = useParams<SiteParams>();

  const hoursData = useContext(ProjectContext);
  const {
    timeSheetFetchStatus,
    timeSheets,
    selectedScope,
    subProjectData,
    subProjectFetchStatus,
    setTimeSheets,
    setSubProjects,
    setTimeSheetStatus,
    setSubProjectStatus,
  } = hoursData;

  useEffect(() => {
    (async () => {
      setTimeSheets([])
      setTimeSheetStatus(HttpRequestStatus.loading);
      try {
        const res = await getTimeSheetEntries(
          parseInt(subProjectId),
          selectedScope
        );
        setTimeSheets(res);
      } catch (err) {
        setTimeSheets([])
        console.log(err);
        setTimeSheetStatus(HttpRequestStatus.error);
      }
    })();
    if (statusNull(subProjectFetchStatus)) {
      (async () => {
        setSubProjectStatus(HttpRequestStatus.loading);
        try {
          const res = await getSubProjectHours();
          setSubProjects(res);
        } catch (err) {
          console.log(err);
          setSubProjectStatus(HttpRequestStatus.error);
        }
      })();
    }
  }, [subProjectId, selectedScope]);// eslint-disable-line react-hooks/exhaustive-deps

  const subProject = subProjectData.find(
    (entry) => entry.SubProjectId === parseInt(subProjectId)
  );

  return (
    <div className="NSF-PortalContent">
      <div className="NSF-Column NSF-SubProject">
        {subProject && (
          <React.Fragment>
            <div className="NSF-Row">
              <h2>Tuntimerkinnät</h2>
              <ScopeSelector />
            </div>

            <div className="NSF-Row">
              <DataCard
                label={subProject.SubProjectName}
                maxValue={subProject.BookedHours}
                value={subProject.ResourcedHours}
              />
            </div>

            <TimeSheets timeSheets={timeSheets} fetchStatus={timeSheetFetchStatus} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SubProject;
