import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import cuid from "cuid";

import { OrderContext } from "../../context/Order";
import { getOrderableAssetsByType } from "../../core/api";
import ActionButton from "../../components/Button/Button";

const stepText: { [key: number]: string } = {
  1: "2. Valitse Tietokone henkilölle",
  2: "3. Valitse Puhelin henkilölle",
  3: "4. Valitse Palvelut henkilölle",
  4: "5. Vahvista Tilaus",
};

interface ActiveOrder {
  employee?: Employee;
  assets: OrderableAsset[];
  order?: Order | null;
}

interface orderParams {
  employeeId: string;
}

const defaultFormState = {
  employee: undefined,
  assets: [],
  order: null,
};

const Order = () => {
  const [activeOrder, setActiveOrder] = useState<ActiveOrder>(defaultFormState);
  const [step, setStep] = useState(1);
  const orderContext = useContext(OrderContext);

  const { state } = orderContext;
  const { employeeId } = useParams<orderParams>();

  useEffect(() => {
    ((async) => {
      const item = state.order.find((item) => item.employee.id === employeeId);
      if (item) {
        setActiveOrder({
          employee: { ...item.employee },
          assets: item.assets ? [...item.assets] : [],
          order: null,
        });
      }
    })();
  }, [employeeId, state.order]);

  const selectAsset = (asset: OrderableAsset) => {
    const order = { ...activeOrder };
    order.assets.push(asset);
    setActiveOrder(order);
    setStep(step + 1);
  };

  const deselectAsset  = (assetId: string) => {
    setActiveOrder((prev) => ({
      ...prev,
      assets: prev.assets.filter(asset => asset.id !== assetId)
    }))
    
  } 

  const getEmployeeName = () => {
    if (activeOrder.employee) {
      return `${activeOrder.employee.firstName} ${activeOrder.employee.lastName}`;
    }
    return "";
  };

  return (
    <React.Fragment>
      <div className="NSF-OrderForm">
        <OrderNavigation
          displayName={getEmployeeName()}
          activeStep={step}
          switchStep={setStep}
        />
      </div>
      <div className="NSF-Row NSF-OrderForm__assets">
        <div className="NSF-Row NSF-OrderForm__assets--container">
          {step === 1 && <AssetList assetType="COMPUTER" selectAsset={selectAsset} deselectAsset={deselectAsset} order={activeOrder} />}
        </div>
        <div className="NSF-Row">
          {step === 2 && <AssetList assetType="PHONE" selectAsset={selectAsset} deselectAsset={deselectAsset} order={activeOrder} />}
        </div>
        <div className="NSF-Row">
          {step === 3 && <AssetList assetType="SOFTWARE" selectAsset={selectAsset} deselectAsset={deselectAsset} order={activeOrder} />}
        </div>
        <div className="NSF-Row">
          {step === 4 && <VerifyOrder activeOrder={activeOrder} />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Order;

interface NavProps {
  displayName: string;
  activeStep: number;
  switchStep: (T: any) => any;
}

const OrderNavigation = ({ displayName, activeStep, switchStep }: NavProps) => {
  return (

    <div className="NSF-Row NSF-OrderForm__navigation">
      {activeStep === 1 && <Link to="/portal/assets/order"><ActionButton text="&larr; Edellinen"/> </Link>}
      {activeStep > 1 && (
        <ActionButton
          type="button"
          text="&larr; Edellinen"
          handleClick={() => switchStep(activeStep - 1)}
          color="red"
        />
      )}
      <h2>
        {stepText[activeStep]} <span>{displayName}</span>
      </h2>
      {activeStep < 4 && (
      <ActionButton
        type="button"
        text="Ohita &rarr;"
        handleClick={() => switchStep(activeStep + 1)}
        color="red"
      />
      )}
      {activeStep === 4 && (
        <div></div>
      )}
    </div>
  );
};

interface AssetItemProps {
  assetType: string;
  order: ActiveOrder;
  selectAsset: (T: any) => any;
  deselectAsset: (T: any) => any;

}

const AssetList = ({ assetType, selectAsset, deselectAsset, order }: AssetItemProps) => {
  const [assets, setAssets] = useState<OrderableAsset[]>([]);

  useEffect(() => {
    (async () => {
      const assets = await getOrderableAssetsByType(assetType);
      setAssets(assets);
    })();
  }, [assetType]);

  const isSelected = (assetId: string)  => {
    const isSelected = order.assets.find(asset => asset.id === assetId) !== undefined;
    return isSelected;
  }

  return (
    <React.Fragment>
      {assets.map((asset) => {
        return (
          <div className="NSF-OrderForm__assets--item" key={cuid()}>
            <div className="NSF-OrderForm__assets--item--description">
              <h2>{asset.displayName}</h2>
              <p>{asset.description}</p>
            </div>
            <p className="NSF-OrderForm__assets--item--price">{asset.price.toFixed(2)} &euro;</p>
            {isSelected(asset.id) ? 
            <ActionButton
              type="button"
              text="poista"
              color="black"
              handleClick={() => deselectAsset(asset.id)}
            />
            :
           <ActionButton
              type="button"
              text="Valitse"
              handleClick={() => selectAsset(asset)}
            />
            }
          </div>
        );
      })}
    </React.Fragment>
  );
};

const VerifyOrder = ({ activeOrder }: any) => {
  const history = useHistory();
  const orderContext = useContext(OrderContext);

  const { dispatch } = orderContext;

  const saveOrder = () => {
    dispatch({ type: "ADD", payload: activeOrder });
    history.push("/portal/assets/order");
  };

  return (
    <div className="NSF-OrderForm__confirm">
      {activeOrder.assets.map((asset: OrderableAsset) => {
        return (
          <div className="NSF-Row NSF-OrderForm__confirm--item" key={cuid()}>
            <p className="NSF-OrderForm__confirm--item-title">
              {asset.displayName}
            </p>
            <p className="NSF-OrderForm__confirm--item-price">
              {asset.price.toFixed(2)} &euro;
            </p>
          </div>
        );
      })}
      <div className="NSF-OrderForm__confirm--total">
        <p>
          Total{" "}
          {activeOrder.assets
            .reduce((r: number, asset: OrderableAsset) => r + asset.price, 0)
            .toFixed(2)}{" "}
          &euro;{" "}
        </p>
      </div>
      <ActionButton
        type="button"
        text="Hyväksy"
        handleClick={saveOrder}
        color="red"
      />
    </div>
  );
};
