import React from "react";
import Overview from "../Overview/Overview";

export const Home = () => {

  return (
    <section className="NSF-PortalContent">
      <div className="NSF-Column">
        <Overview />
      </div>
    </section>
  );
};

export default Home;
