import { Scope } from "../context/Hours";
import { get, post, put, remove } from "./httpClient";

const API_VERSION = "v1"

export const healtCheck = () => {
  return get<User>("/v1/health");
}

export const loginUser = (email: string, password: string) => {
  return post<User>("/v1/auth/login", { email, password });
};

export const loginWithToken = () => {
  return get<User>("/v1/auth/refresh");
};

export const changePassword = (oldPassword: string, password: string) => {
  return post<User>("/v1/auth/reset-password", { oldPassword, password });
};

export const getProjectHours = (scope: Scope) => {
  return get<ProjectHoursData>(`/v1/project-hours/projects?scope=${scope}`);
};

export const getSubProjectHours = () => {
  return get<SubProjectHoursData[]>("/v1/project-hours/sub-projects");
};

export const getTimeSheetEntries = (subProjectId: number, scope: Scope) => {
  return get<TimeSheetEntry[]>(`/${API_VERSION}/project-hours/timesheets?scope=${scope}&subProjectId=${subProjectId}`)
}

export const getEmployees = () => {
  return get<Employee[]>(`/${API_VERSION}/employees`);
}

export const getAssets = () => {
  return get<Asset[]>(`/${API_VERSION}/assets`)
}

export const submitAsset = (asset: Asset) => {
  return post<Asset>(`/${API_VERSION}/assets`, {...asset})
}

export const updateAsset = (asset: Asset) => {
  return put<Asset>(`/${API_VERSION}/assets`, {...asset})
}

export const getAssetHistory = (assetId: string) => {
  return get<AssetHistory[]>(`/${API_VERSION}/assets/history?id=${assetId}`)
}

export const submitEmployee = (employee: Employee) => {
  return post<Employee>(`/${API_VERSION}/employees`, {...employee})
}

export const updateEmployee = (employeeId: string, employee: Employee) => {
  return put<Employee>(`/${API_VERSION}/employees?id=${employeeId}`, {...employee})
}

export const removeEmployee = (employeeId: string) => {
  return remove<Employee>(`/${API_VERSION}/employees?id=${employeeId}`)
}

export const getOrderableAssetsByType = (assetType: string) => {
  return get<OrderableAsset[]>(`/${API_VERSION}/orders/assets?type=${assetType}`)
}
export const submitOrder = (assets: Asset[]) => {
  return post<Asset[]>(`/${API_VERSION}/orders/submit`, [...assets])
}