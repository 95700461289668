import React, { useContext } from "react";
import ActionButton from "../Button/Button";
import { ProjectContext, Scope } from "../../context/Hours";
import { HttpRequestStatus } from "../../utils/http";
import cuid from "cuid";

const ScopeSelector = () => {
  const hoursContext = useContext(ProjectContext);
  const {
    selectedScope,
    setSelectedScope,
    setProjectStatus,
  } = hoursContext;

  const handleSelectScope = (scope: Scope) => {
    setProjectStatus(HttpRequestStatus.loading);
    setSelectedScope(scope);
  };

  const scopes = [Scope.WEEK, Scope.MONTH, Scope.YEAR];

  const getScopeString = (scope: Scope) => {
    switch (scope) {
      case Scope.WEEK:
        return "Viikko";
      case Scope.MONTH:
        return "Kuukausi";
      case Scope.YEAR:
        return "Vuosi";
      default:
        return "";
    }
  };
  return (
    <div className="NSF-ScopeSelector">
      {scopes.map((scope) => (
        <div className="NSF-ScopeSelector__item" key={cuid()}>
          <ActionButton
            text={getScopeString(scope)}
            color={scope === selectedScope ? "red" : "white"}
            handleClick={() => handleSelectScope(scope)}
          />
        </div>
      ))}
    </div>
  );
};

export default ScopeSelector;
