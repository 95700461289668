import React from "react";

import { NavLink as Link } from "react-router-dom";
import {
  FaHome,
  FaUsers,
  FaShoppingCart,
  FaLaptop,
  FaUser,
  FaSignOutAlt,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaToolbox
} from "react-icons/fa/";
import { logout } from "../../core/auth";

const ListLink: React.FunctionComponent<{ path?: string, handleClick?: () => void }> = (props) => {
  if (!props.path) {
    return (
      <li onClick={props.handleClick}>
        <div className="NSF-NavigationLink">{props.children}</div>
      </li>
    );
  }
  return (
    <li>
      <Link
        onClick={props.handleClick}
        className="NSF-NavigationLink"
        isActive={(match, location) => {
          return !!match && location.pathname === props.path;
        }}
        activeClassName="NSF-NavigationLink--Active"
        to={props.path}
      >
        {props.children}
      </Link>
    </li>
  );
};

const Navigation = (props: any) => (
  <section className="NSF-Navigation">
    <nav className="NSF-Navigation__links">
      <ul>
        <ListLink path="/portal">
          <FaHome />
          <span>Tilannekatsaus</span>
        </ListLink>
        <ListLink path="/portal/employees">
          <FaUsers />
          <span>Työntekijät</span>
        </ListLink>
        <ListLink path="/portal/assets">
          <FaToolbox />
          <span>Laitteet</span>
        </ListLink>
        <ListLink path="/portal/assets/order">
          <FaShoppingCart />
          <span>Siirry tilaamaan</span>
        </ListLink>
        <ListLink>
          <FaLaptop />
          <span>Omat tilaukset</span>
        </ListLink>
        <ListLink path="/portal/account">
          <FaUser />
          <span>Oma tili</span>
        </ListLink>
        <ListLink path="#" handleClick={logout}>
          <FaSignOutAlt />
          <span>Kirjaudu ulos</span>
        </ListLink>
      </ul>
    </nav>
    <div className="NSF-NaviHelp">
      <p>Helpdeskimme palvelee teitä numerossa:</p>
      <p>
        <b>0201 42 42 42</b>
      </p>
    </div>
    <div className="NSF-NaviCompany">
      <p>Network Services Finland Oy</p>
      <div className="NSF-NaviCompany__someList">
        <FaFacebook />
        <FaTwitter />
        <FaLinkedin />
      </div>
    </div>
  </section>
);

export default Navigation;
