import React, { createContext, FunctionComponent, useState } from "react";
import { HttpRequestStatus } from "../utils/http";

interface Context {
  assetsSubmitStatus: HttpRequestStatus;
  assetsFetchStatus: HttpRequestStatus;
  assets: Asset[];

  setAssetsSubmitStatus: (status: HttpRequestStatus) => void;
  setAssetsFetchStatus: (status: HttpRequestStatus) => void;
  setAssets: (assets: Asset[]) => void;
}

const defaultFn = () => {
  throw Error("ContextFn has not been initialized correctly");
};

const defaultState = {
  assetsFetchStatus: HttpRequestStatus.null,
  assetsSubmitStatus: HttpRequestStatus.null,
  assets: [],

  setAssetsFetchStatus: defaultFn,
  setAssetsSubmitStatus: defaultFn,
  setAssets: defaultFn,
};

export const AssetsContext = createContext<Context>(defaultState);

const AssetsProvider: FunctionComponent = (props) => {
  const [state, setState] = useState<Context>(defaultState);

  const setAssets = (assets: Asset[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        assets,
      };
    });
  };

  const setAssetsFetchStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return {
        ...prev,
        assetsFetchStatus: status,
      };
    });
  };

  const setAssetsSubmitStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => ({
      ...prev, 
      assetsSubmitStatus: status
    }))
  }

  const initState: Context = {
    ...state,
    setAssets,
    setAssetsFetchStatus,
    setAssetsSubmitStatus,
  };

  return (
    <AssetsContext.Provider value={initState}>
      {props.children}
    </AssetsContext.Provider>
  );
};

export default AssetsProvider;
