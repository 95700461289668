import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { UserConsumer } from "./context/User";
import Home from "./pages/home/home";
import AuthenticatedRoute from "./components/AuthenticatedRoute/AuthenticatedRoute";
import Login from "./pages/login/login";
import AccountPage from "./pages/AccountPage/AccountPage";
import RootProvider from "./context/Root";
import ProjectHours from "./pages/ProjectHours/ProjectHours";
import SubProject from "./pages/SubProject/SubProject";
import Employees from "./pages/Employees/Employees";
import NewEmployee from "./pages/Employees/NewEmployee";
import UpdateEmployee from "./pages/Employees/UpdateEmployee";
import OrderConfirm from "./pages/Order/OrderConfirm";
import OrderHome from "./pages/Order/OrderHome";
import Order from "./pages/Order/Order";
import UpdateAsset from "./pages/Assets/UpdateAsset";
import NewAsset from "./pages/Assets/NewAsset";
import HistoryAsset from "./pages/Assets/HistoryAsset";
import Assets from "./pages/Assets/Assets";


const App = () => {
  return (
    <Router>
      <RootProvider>
        <div className="NSF-Container">
          <Switch>
            <AuthenticatedRoute path="/portal">
              <div className="NSF-Layout">
                <Sidebar />
                <div className="NSF-App">
                  <Header />
                  <div className="NSF-Portal">
                    <Route exact path="/portal" component={Home} />
                    <Route
                      exact
                      path="/portal/assets"
                      component={Assets}
                    />
                    <Route 
                      exact
                      path="/portal/order/confirm"
                      component={OrderConfirm}
                    />
                    <Route
                      exact
                      path="/portal/assets/order"
                      component={OrderHome}
                    />
                    <Route 
                      exact
                      path="/portal/assets/order/:employeeId"
                      component={Order}
                    />
                    <Route
                      expect
                      path="/portal/assets/new"
                      component={NewAsset}
                    />
                    <Route
                      exact
                      path="/portal/assets/modify/:assetId"
                      component={UpdateAsset}
                    />
                    <Route
                      exact
                      path="/portal/assets/history/:assetId"
                      component={HistoryAsset}
                    />
                    <Route
                      exact
                      path="/portal/employees"
                      component={Employees}
                    />
                    <Route
                      exact
                      path="/portal/employees/new"
                      component={NewEmployee}
                    />
                    <Route
                      exact
                      path="/portal/employees/edit/:userId"
                      component={UpdateEmployee}
                    />
                    <Route
                      exact
                      path="/portal/projecthours"
                      component={ProjectHours}
                    />
                    <Route
                      exact
                      path="/portal/subprojecthours"
                      component={ProjectHours}
                    />
                    <Route
                      exact
                      path="/portal/subproject/:subProjectId"
                      component={SubProject}
                    />
                    <Route
                      exact
                      path="/portal/maintenancehours"
                      component={ProjectHours}
                    />
                    <Route
                      exact
                      path="/portal/account"
                      component={AccountPage}
                    />
                  </div>
                </div>
              </div>
            </AuthenticatedRoute>
            <UserConsumer>
              {(value) => (
                <Route
                  path="/"
                  render={() => {
                    const loggedIn = !!value.data;
                    if (loggedIn) {
                      return <Redirect to="/portal" />;
                    }
                    return <Login />;
                  }}
                />
              )}
            </UserConsumer>
          </Switch>
        </div>
      </RootProvider>
    </Router>
  );
};

export default App;
