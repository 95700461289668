import React, { ChangeEvent, FormEvent } from "react";
import Input from "../Input/Input";
import ActionButton from "../Button/Button"; 

export interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  valid: boolean;
  submitted: boolean;
}

interface Props {
  formState: FormState;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  valid: boolean;
}

export const EmployeeForm = ({ formState, handleChange, handleSubmit, valid }: Props) => (
  <form
    className="NSF-EmployeeForm__form"
    onSubmit={handleSubmit}
    autoComplete="off"
  >
    <Input
      className="NSF-EmployeeForm__form--textinput"
      id="firstName"
      label="Etunimi"
      type="text"
      value={formState.firstName}
      onChange={handleChange}
    />
    <Input
      className="NSF-EmployeeForm__form--textinput"
      id="lastName"
      label="Sukunimi"
      type="text"
      value={formState.lastName}
      onChange={handleChange}
    />
    <Input
      className="NSF-EmployeeForm__form--textinput"
      id="email"
      label="Sähköposti"
      type="text"
      value={formState.email}
      onChange={handleChange}
    />
    <Input
      className="NSF-EmployeeForm__form--textinput"
      id="phoneNumber"
      label="Puhelinnumero"
      type="text"
      value={formState.phoneNumber}
      onChange={handleChange}
    />

    <ActionButton
      type="submit"
      text="Tallenna"
      handleClick={handleSubmit}
      disabled={!valid}
    />
  </form>
);
