import React, { useContext, useEffect } from "react";
import { ProjectContext } from "../../context/Hours";
import { getProjectHours, getSubProjectHours } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import OverviewCard from "../../components/Card/OverviewCard";
import ContactCard from "../../components/Card/ContactCard";

const Overview = () => {
  const hoursData = useContext(ProjectContext);
  const {
    projectFetchStatus,
    subProjectFetchStatus,
    projectData,
    subProjectData,
    selectedScope,
    setProjectStatus,
    setSubProjectStatus,
    setSubProjects,
    setProjects,
  } = hoursData;

  // Fetching main project data
  useEffect(() => {
    if (statusNull(projectFetchStatus)) {
      // Update status
      setProjectStatus(HttpRequestStatus.loading);

      // Fetch
      getProjectHours(selectedScope)
        .then((hours) => {
          setProjects(projectData.concat(hours));
        })
        .catch((err) => {
          console.log(err);
          setProjectStatus(HttpRequestStatus.error);
        });
    }
  }, [projectFetchStatus, projectData, selectedScope, setProjectStatus, setProjects]);

  // Fetching sub project data
  useEffect(() => {
    if (statusNull(subProjectFetchStatus)) {
      // Update status
      setSubProjectStatus(HttpRequestStatus.loading);

      // Fetch
      getSubProjectHours()
        .then((subHours) => {
          setSubProjects(subHours);
        })
        .catch((err) => {
          console.log(err);
          setSubProjectStatus(HttpRequestStatus.error);
        });
    }
  }, [
    subProjectFetchStatus,
    setSubProjectStatus,
    setSubProjects,
    subProjectData,
  ]);

  return (
    <div className="NSF-Overview">
      <div className="NSF-Row">
        <h2>Tilannekatsaus</h2>
      </div>
      <div className="NSF-Row">
        <OverviewCard
          projectData={subProjectData.filter((dataItem) =>
            dataItem.SubProjectName.includes("1. Ylläpito")
          )}
          path="/portal/maintenancehours"
          label="Ylläpito"
          sublabel="Kuukausilaskutus"
        />
          <OverviewCard
            path="/portal/projecthours"
            projectData={projectData}
            label="Hankkeet"
            sublabel="Suuremmat projektit"
          />
      </div>

      <div className="NSF-Row">
        <OverviewCard
          path="/portal/subprojecthours"
          projectData={subProjectData.filter(
            (dataItem) => !dataItem.SubProjectName.includes("1. Ylläpito")
          )}
          label="Pienprojektit"
          sublabel="Pienet lisätyöt"
        />
        <ContactCard />
      </div>
    </div>
  );
};

export default Overview;
