import React, { useState } from "react";
import AssetForm from "../../components/Assets/AssetForm";
import { EmployeesDropdown } from "../../components/Employees/EmployeesDropdown";
import { submitAsset } from "../../core/api";
import {v4 as uuidv4} from "uuid";

const defaultAsset: Asset = {
  id: "",
  displayName: "",
  companyId: "",
  employeeId: "",
  type: "PHONE",
  purchaseDate: new Date(),
  assetMetadata: [],
};

const NewAsset = () => {
  const [ asset, setAsset ] = useState<Asset>(defaultAsset);
  const handleEmployeeSelect = (option: Employee) => {
    setAsset(prev => ({
      ...prev,
      employeeId: option.id ? option.id : "",
      companyId: option.companyId ? option.companyId : "",
    }));
  }

  const handleSubmit = async (form: Asset) => {
    form.id = uuidv4();
    return submitAsset(form);
  }
  
  return (
    <div className="NSF-AssetForm">
      <div className="NSF-Row">
        <h1 className="NSF-PageTitle">Lisää uusi laite</h1>
      </div>
      <div className="NSF-Row NSF-EmployeeSelect">
        <EmployeesDropdown onSelect={handleEmployeeSelect}/>
      </div>
      <AssetForm defaultAsset={asset} submitButtonText="Lisää" submitForm={handleSubmit} />
    </div>
  );
};

export default NewAsset;
