import React from "react";
import ActionButton from "../Button/Button";

const ContactCard = () => (
  <div className="NSF-Card">
    <div className="NSF-DataCard NSF-ContactCard">
      <div className="NSF-DataCard__info">
        <div className="NSF-DataCard__info--label">Asiakkuusvastaava</div>
        <div className="NSF-DataCard__info--sublabel">
          Marjatta Pilvilampi
          <br />
          +358 45 454 1045
        </div>
        <div className="NSF-DataCard__actions">
          <ActionButton text="Ota yhteyttä" handleClick={() => {}} />
        </div>
      </div>
    </div>
  </div>
);

export default ContactCard;
