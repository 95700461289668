import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { EmployeeForm, FormState } from "../../components/Employees/EmployeeForm";
import { EmployeesContext } from "../../context/Employees";
import { submitEmployee } from "../../core/api";
import { HttpRequestStatus } from "../../utils/http";
import { notEmpty, validEmail } from "../../utils/validate";
import { BackButton } from "../../components/Button/Button";

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  valid: false,
  submitted: false,
};

const NewEmployee = () => {
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const employeesContext = useContext(EmployeesContext);
  const history = useHistory();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validate = (formState: FormState) => {
    return (
      notEmpty(formState.firstName) &&
      notEmpty(formState.lastName) &&
      validEmail(formState.email)
    );
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      valid: validate(prev),
    }));
  }, [formState.firstName, formState.lastName, formState.email]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState((prev) => ({
      ...prev,
      submitted: true,
    }));
    try {
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.loading);
      await submitEmployee(formState);
      employeesContext.setEmployeeSubmitStatus(HttpRequestStatus.ready);
      employeesContext.setEmployeesFetchStatus(HttpRequestStatus.null);
      history.push("/portal/employees");
    } catch (err) {
      console.log(err);
    }
  };

  const { valid, submitted } = formState;

  return (
    <div className="NSF-EmployeeForm">
      <div className="NSF-Row">
        <h1 className="NSF-PageTitle">Lisää uusi työntekijä</h1>
      </div>
      <div className="NSF-Row">
        <EmployeeForm
          formState={formState}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          valid={valid && !submitted}
        />

      </div>
      <div className="NSF-Row">
        <BackButton text="Takaisin" className="NSF-EmployeeForm--back"/>
      </div>
    </div>
  );
};

export default NewEmployee;
