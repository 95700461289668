import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import cuid from "cuid";
import { getAssetHistory } from "../../core/api";
import { BackButton } from "../../components/Button/Button";


interface AssetParams {
  assetId: string;
}

const strMap: { [key: string]: string; } = {
  "order": "Tilaus",
  "purchaseDate": "Ostospäivä",
  "displayName": "Nimi"
}

const HistoryAsset = () => {
  const [assetHistory, setAssetHistory] = useState<AssetHistory[]>([]);

  const { assetId } = useParams<AssetParams>();
  useEffect(() => {
    (async () => {
      try {
        getAssetHistory(assetId).then(history => {
          setAssetHistory(history);  
        })
      } catch (err) {
        console.log(err);
      }
    })();
  }, [assetId]);
  
  const formatDisplayDate = (date: Date) => {
    return new Intl.DateTimeFormat('fi-FI').format(new Date(date));
  }

  const formatOutput = (objKey: string, objValue: string) => {
    objValue = objValue !== "null" ? objValue : "-";
    objKey = strMap[objKey] !== undefined ? strMap[objKey] : objKey;

    if(objKey === "Tilaus") {
      return <><p>{objKey}</p><p>Päivitetty</p></>
    } 

    return <><p>{objKey}</p><p>{objValue}</p></>
  } 

  return (
    <div className="NSF-AssetHistory">
      <div className="NSF-Row">
        <div className="NSF-PageTitle">Laitteen historiikka</div>
      </div>
      <div className="NSF-AssetHistory__Headers"><h2>Otsikko</h2><h2>Vanha tieto</h2><h2>Päivämäärä</h2><h2>Muuttaja</h2></div>
      {assetHistory.length > 0 &&
          assetHistory.map(({objectKey, objectValue, changeDate, userDisplayName}) => (
            <div className="NSF-AssetHistory__Row" key={cuid()}>{formatOutput(objectKey, objectValue)}<p>{formatDisplayDate(changeDate)}</p><p>{userDisplayName}</p></div>
          ))
      }
      {assetHistory.length === 0 && <div className="NSF-AssetHistory__Row--NoData"><h2>Laittehistoriaa ei löydetty</h2></div>}
      <div className="NSF-AssetHistory__Row--button">
        <BackButton text="Takaisin" />
      </div>
    </div>
  )
}

export default HistoryAsset;
