import React, {
  Dispatch,
  createContext,
  FunctionComponent,
  useReducer,
  useEffect,
} from "react";

import { readToken } from "../utils/browser";
type OrderAction =
  | { type: "ADD"; payload: ShoppingCartItem }
  | { type: "DELETE"; payload: { employeeId: string; assetId: string } }
  | { type: "CLEAR" };

interface ContextModel {
  state: ShoppingCart;
  dispatch: Dispatch<OrderAction>;
}

let reducer = (state: ShoppingCart, action: OrderAction): ShoppingCart => {
  switch (action.type) {
    case "ADD":
      // Update order
      let items = state.order.map((item) => {
        if (item.employee.id === action.payload.employee.id) {
          return action.payload;
        }
        return item;
      });
      // Add new employee to order
      if (
        !state.order.find(
          (item) => item.employee.id === action.payload.employee.id
        )
      ) {
        // employee not found in order
        items = [...items, action.payload];
      }

      return {
        ...state,
        order: [...items],
      };
    case "DELETE":
      let order = state.order.map((item) => {
        if (item.employee.id === action.payload.employeeId) {
          return {
            ...item,
            assets: item.assets?.filter(
              (asset) => asset.id !== action.payload.assetId
            ),
          };
        }
        return item;
      });

      return {
        ...state,
        order: [...order],
      };
    case "CLEAR":
      return {
        ...state,
        order: [],
      };
    default:
      return state;
  }
};

const getLocalState = () => {
  const localState = JSON.parse(localStorage.getItem("order-session") || "{}");
  const jwt = readToken();
  
  if (localState.session === jwt) {
    return localState;
  }
  if (localState.session !== null && localState.order) {
    return { order: [...localState.order], session: jwt };
  }
  return { order: [], session: jwt };
};

export const OrderContext = createContext({} as ContextModel);

const OrderProvider: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalState()
  );

  useEffect(() => {
    localStorage.setItem("order-session", JSON.stringify(state));
  }, [state]);

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
