import cuid from "cuid";
import moment from "moment";
import React from "react";
import { HttpRequestStatus } from "../../utils/http";

interface Props {
  timeSheets: TimeSheetEntry[];
  fetchStatus: HttpRequestStatus;
}

interface RowProps {
  timeSheet: TimeSheetEntry;
}

const TimeSheetRow = ({ timeSheet }: RowProps) => {
  const {
    EmployeeName,
    StartDate,
    TimeSheetEntryHours,
    TimeSheetEntryDescription,
  } = timeSheet;
  return (
    <div className="NSF-TimeSheet">
      <div className="NSF-TimeSheet__name">{EmployeeName}</div>
      <div className="NSF-TimeSheet__date">
        {moment(StartDate).format("DD.MM.YYYY")}
      </div>
      <div className="NSF-TimeSheet__description">
        {TimeSheetEntryDescription}
      </div>
      <div className="NSF-TimeSheet__hours">{TimeSheetEntryHours} h</div>
    </div>
  );
};

const TimeSheets = ({ timeSheets, fetchStatus }: Props) => {
  return (
    <div className="NSF-TimeSheets">
      {timeSheets.map((entry) => (
        <TimeSheetRow key={cuid()} timeSheet={entry} />
      ))}
    </div>
  );
};

export default TimeSheets;
