import cuid from "cuid";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MdSecurity } from "react-icons/md";
import { FaLaptop, FaMobileAlt } from "react-icons/fa";
import { TiFlowSwitch } from "react-icons/ti";
import { RiRouterLine, RiEditLine, RiHistoryLine, RiBookLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";

interface RowProps {
  asset: Asset;
}

const DeviceRow = ({ asset }: RowProps) => {
  const [showDevice, setShowDevice] = useState(false);

  const toggleShowDevice = () => {
    setShowDevice(!showDevice);
  };

  const assetIcon = (asset: Asset) => {
    
    switch (asset.type) {
      case "PHONE":
        return <FaMobileAlt />;
               
      case "COMPUTER":
        return <FaLaptop />;
      case "FIREWALL":
        return <MdSecurity />;
      case "SWITCH":
        return <TiFlowSwitch />;
      case "WLAN":
        return <RiRouterLine />;
      case "SOFTWARE":
        return <RiBookLine />;
      default:
        break;
    }
  };

  const formatStatus = (order: Order | undefined) => {
    if(order === undefined) return "";
    switch (order.status) {
      case "ORDERED":
        return "Ei vielä lähetetty";
      case "SHIPMENT":
        return "Arvioitu saapumisaika " + new Intl.DateTimeFormat("fi-FI").format(new Date(order.estimatedDeliveryDate));
      case "DELIVERED":
        return "Toimitettu " + new Intl.DateTimeFormat("fi-FI").format(new Date(order.estimatedDeliveryDate));
      default:
        return "";
    }
  }
  const formatDate = (order: Order | undefined) => {
    if(order === undefined) return "Laitetta ei ole tilattu";
    return "Tilattu " + new Intl.DateTimeFormat("fi-FI").format(new Date(order.orderPlacedDate));
  };

  const getOrderStatus = (asset: Asset) => {
    return asset.order?.status;
  };

  return (
    <React.Fragment>
      <div className="NSF-DeviceList__row" key={cuid()}>
        <button
          onClick={toggleShowDevice}
          className={`NSF-DeviceList__row--arrow ${
            showDevice && "NSF-DeviceList__row--arrow-active"
          }`}
        >
          <IoIosArrowForward />
        </button>
        <div className="NSF-DeviceList__row--icon">
         <div className={`NSF-EmployeeDevices__deviceIcon NSF-EmployeeDevices__deviceIcon--${getOrderStatus(asset)}`}>
          {assetIcon(asset)}
          </div>
        </div>
        <div className="NSF-DeviceList__row--name">
          <p>{asset.displayName}</p>
        </div>
        <div className="NSF-DeviceList__row--status">
          <p>{formatDate(asset.order)}</p>
        </div>
        <div className="NSF-DeviceList__row--orderinfo">
          <p>{formatStatus(asset.order)}</p>
        </div>
        <DeviceDetailedRow asset={asset} visible={showDevice} />
      </div>
    </React.Fragment>
  );
};

interface AssetProps {
  asset: Asset;
  visible: boolean;
}

const DeviceDetailedRow = ({ asset, visible }: AssetProps) => {
  
  if (!visible) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="NSF-DeviceList__row--details">
        {asset.assetMetadata.map(({ objectKey, objectValue }) => (
          <div className="NSF-DeviceList__row--info" key={cuid()}>
            <p className="NSF-DeviceList__row--title">{objectKey}</p>
            <p className="NSF-DeviceList__row--value">
              {objectValue}
            </p>
            {" "}
          </div>
        ))}
      </div>
      <div className="NSF-DeviceList__row--asset-modify">
        <Link to={`/portal/assets/history/${asset.id}`}>
          <RiHistoryLine /> Historiikka
        </Link>
        <Link to={`/portal/assets/modify/${asset.id}`}>
          <RiEditLine /> Muokkaa
        </Link>
      </div>
    </React.Fragment>
  );
};

interface Props {
  assets: Asset[];
  visible: boolean;
}

const DeviceList = ({ assets, visible }: Props) => {
  return (
    <div
      className={`NSF-DeviceList ${
        visible && "NSF-DeviceList--show"
      }`}
    >
      {assets.map((asset) => (
        <DeviceRow asset={asset} key={cuid()} />
      ))}
    </div>
  );
};

export default DeviceList;
