import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ActionButton from "../../components/Button/Button";
import EmployeesList from "../../components/EmployeesList/EmployeesList";
import { EmployeesFilter } from "../../components/Employees/EmployeesFilter";
import { AssetsContext } from "../../context/Assets";
import { EmployeesContext } from "../../context/Employees";
import { getAssets, getEmployees } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";

const Employees = () => {
  const employeesContext = useContext(EmployeesContext);
  const assetsContext = useContext(AssetsContext);
  const [ freeSearchText, setFreeSearchText ] = useState<string>();
  const {
    employeesFetchStatus,
    employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = employeesContext;

  const {
    assetsFetchStatus,
    assets,

    setAssets,
    setAssetsFetchStatus,
  } = assetsContext;

  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployeesFetchStatus(HttpRequestStatus.ready);
          setEmployees(employees);
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    }

    if (statusNull(assetsFetchStatus)) {
      (async () => {
        try {
          setAssetsFetchStatus(HttpRequestStatus.loading);
          const assets = await getAssets();
          setAssetsFetchStatus(HttpRequestStatus.ready);
          setAssets(assets);
        } catch (err) {
          console.log(err);
          setAssetsFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
  });

  const onFreeSearch = (searchText: string) => {
    setFreeSearchText(searchText);
  }

  const getEmployeesList = () => {
    let list = [...employees];
    if(freeSearchText !== undefined) {
      list = list.filter(employee => (employee.firstName.concat(" " + employee.lastName.toString())).toLowerCase().includes(freeSearchText));
    }
    return list;
  }

  return (
    <div className="NSF-Employees">
      <div className="NSF-Row">
        <h1 className="NSF-PageTitle">Työntekijät</h1>
        <EmployeesFilter handleFreeSearch={onFreeSearch} />
        <Link to="/portal/employees/new" className="NSF-Employees--New">
          <ActionButton text="Lisää uusi" />
        </Link>
      </div>
      <div className="NSF-Row">
        <EmployeesList employees={getEmployeesList()} assets={assets} />
      </div>
    </div>
  );
};

export default Employees;
