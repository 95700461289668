import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import DeviceList from "../../components/Assets/DeviceList";
import { AssetsContext } from "../../context/Assets";
import { EmployeesContext } from "../../context/Employees";
import { getAssets, getEmployees } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";
import AssetFilter from "../../components/Assets/AssetFilter";
import ActionButton from "../../components/Button/Button";

interface TypeFilterOption {
  value: AssetType | "TYPE_ALL";
  label: string;
}
interface StatusFilterOption {
  value: OrderStatus | "TYPE_ALL";
  label: string;
}
const Assets = () => {
  const employeesContext = useContext(EmployeesContext);
  const assetsContext = useContext(AssetsContext);
  const [typeFilter, setTypeFilter] = useState<AssetType | null>(null);
  const [statusFilter, setStatusFilter] = useState<OrderStatus | null>(null);
  const [freeSearchText, setFreeSearchText] = useState<string>();
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const assetPerPage = 25;
  const {
    employeesFetchStatus,
    // employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = employeesContext;

  const {
    assetsFetchStatus,
    assets,

    setAssets,
    setAssetsFetchStatus,
  } = assetsContext;

  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployeesFetchStatus(HttpRequestStatus.ready);
          setEmployees(employees);
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
    if (statusNull(assetsFetchStatus)) {
      (async () => {
        try {
          setAssetsFetchStatus(HttpRequestStatus.loading);
          const assets = await getAssets();
          setAssetsFetchStatus(HttpRequestStatus.ready);
          setAssets(assets);
          setPageCount(assets.length / assetPerPage);
        } catch (err) {
          console.log(err);
          setAssetsFetchStatus(HttpRequestStatus.error);
        }
      })();
    }
  }, [
    assets,
    assetsFetchStatus,
    employeesFetchStatus,
    setAssets,
    setAssetsFetchStatus,
    setEmployees,
    setEmployeesFetchStatus,
  ]);

  const onSelectType = (option: TypeFilterOption) => {
    if (option.value !== "TYPE_ALL") {
      setTypeFilter(option.value);
    } else {
      setTypeFilter(null);
    }
  };
  const onSelectStatus = (option: StatusFilterOption) => {
    if (option.value !== "TYPE_ALL") {
      setStatusFilter(option.value);
    } else {
      setStatusFilter(null);
    }
  };
  const onFreeSearch = (searchText: string) => {
    setFreeSearchText(searchText);
  };

  const assetList = () => {
    if (assets.length === 0) return;
    let list = [...assets];
    //filter
    if (typeFilter) {
      list = list.filter((asset) => asset.type === typeFilter);
    }
    if (statusFilter) {
      list = list.filter((asset) => asset.order?.status === statusFilter);
    }
    if (freeSearchText !== undefined) {
      list = list.filter((asset) =>
        asset.assetMetadata.some(
          (metadata) =>
            metadata.objectValue.toLowerCase().includes(freeSearchText) ||
            asset.displayName.toLowerCase().includes(freeSearchText)
        )
      );
    }

    // Disable/Enable pagination
    if (list.length < assetPerPage && pageCount > 1) {
      setPageCount(1);
      setOffset(0);
    } else if (list.length > assetPerPage && pageCount <= 1) {
      setPageCount(list.length / assetPerPage);
    }

    return (
      <DeviceList
        assets={list.slice(
          offset * assetPerPage,
          offset * assetPerPage + assetPerPage
        )}
        visible={true}
      />
    );
  };

  const handlePageChange = (data: any) => {
    setOffset(data.selected);
  };

  return (
    <div className="NSF-Devices">
      <Link to="/portal/assets/new">
        <ActionButton text="lisää laite" />
      </Link>
      <div className="NSF-Row">
        <h1 className="NSF-PageTitle">Laitteet</h1>
        <AssetFilter
          handleSelectType={onSelectType}
          handleSelectStatus={onSelectStatus}
          handleFreeSearch={onFreeSearch}
        />
      </div>
      <div className="NSF-AssetsList">
        {assetList()}
        <div className="NSF-AssetsList__pagination">
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={"edellinen"}
              nextLabel={"seuraava"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={pageCount < 5 ? pageCount : 5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Assets;
