import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import ActionButton from "../../components/Button/Button";
import OrderEmployeesList from "../../components/Order/OrderEmployeesList";
import { EmployeesContext } from "../../context/Employees";
import { OrderContext } from "../../context/Order";
import { getEmployees } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";

const OrderHome = () => {
  const employeesContext = useContext(EmployeesContext);
  const orderContext = useContext(OrderContext);

  const {
    employeesFetchStatus,
    employees,

    setEmployees,
    setEmployeesFetchStatus,
  } = employeesContext;

  const { state, dispatch } = orderContext;

  useEffect(() => {
    if (statusNull(employeesFetchStatus)) {
      (async () => {
        try {
          setEmployeesFetchStatus(HttpRequestStatus.loading);
          const employees = await getEmployees();
          setEmployeesFetchStatus(HttpRequestStatus.ready);
          setEmployees(employees);
          employees.forEach((employee) => {
            const found = state.order.find(
              (item) => item.employee.id === employee.id
            );
            if (!found) {
              dispatch({ type: "ADD", payload: { employee } });
            }
          });
        } catch (err) {
          console.log(err);
          setEmployeesFetchStatus(HttpRequestStatus.error);
        }
      })();
    } else {
      // check if employee in order, else add to order
      employees.forEach((employee) => {
        const found = state.order.find(
          (item) => item.employee.id === employee.id
        );
        if (!found) {
          dispatch({ type: "ADD", payload: { employee } });
        }
      });
    }
  }, [
    employees,
    employeesFetchStatus,
    setEmployees,
    setEmployeesFetchStatus,
    state,
    dispatch,
  ]);

  const getTotalPrice = () => {
    return state.order.reduce(
      (total, items) =>
        total +
        (items.assets !== undefined
          ? items.assets.reduce((acc, asset) => acc + asset.price, 0)
          : 0),
      0
    );
  };

  return (
    <div className="NSF-OrderForm">
      <div className="NSF-Row NSF-OrderForm__employees">
        <div className="NSF-ROW NSF-OrderForm__employees--header">
          <div className="NSF-OrderForm__employees--header-title">
            <h2 className="NSF-PageTitle">Työntekijöiden valinta</h2>
            <span className="NSF-PageSubTitle">
              Aloita tilaaminen valitsemalla työntekijät
            </span>
          </div>
          <div className="NSF-OrderForm__employees--header-new">
            <Link to="/portal/employees/new" className="NSF-Employees--New">
              <ActionButton text="Lisää uusi työntekijä" />
            </Link>
          </div>
        </div>
        <OrderEmployeesList cart={state} />
        <div className="NSF-OrderForm__total">
          <p className="NSF-OrderForm__total--price">
            Yhteensä: <span>{getTotalPrice().toFixed(2)} &euro; </span>
          </p>
          <Link to="/portal/order/confirm">
            <ActionButton text="Siirry vahvistamaan" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderHome;
