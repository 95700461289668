import axios from "axios";
import { readToken } from "../utils/browser";
import { apiUrl } from "./config";

const getAuthHeader = () => {
  const token = readToken();
  return token ? `Bearer ${token}` : undefined;
};

const getPostHeaders = (): any => ({
  authorization: getAuthHeader(),
  "content-type": "application/json",
});

export async function get<R>(route: string): Promise<R> {
  const response = await axios.get<R>(apiUrl + route, {
    headers: {
      authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function post<R>(route: string, body: any): Promise<R> {
  const headers = getPostHeaders();
  const response = await axios.post<R>(
    apiUrl + route,
      body,
    { headers: headers }
  );
  return response.data;
}

export async function put<R>(route: string, body: any): Promise<R> {
  const headers = getPostHeaders();
  const response = await axios.put<R>(
    apiUrl + route,
      body,
    { headers: headers }
  );
  return response.data;
}

// delete is a reserved function name
export async function remove<R>(route: string): Promise<R> {
  const headers = getPostHeaders();
  const response = await axios.delete<R>(
    apiUrl + route,
    { headers: headers }
  );
  return response.data;
}